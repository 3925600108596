















import { defineComponent, ref } from '@vue/composition-api';
import { useVehicleLog } from '@/mixins/forms';
import { VehicleForm, TableHeader } from '@/models';


export default defineComponent({
  name: "VehicleLog",
  props: {
    secret: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const { vehicles } = useVehicleLog(props);
    const search = ref("");
    const headers: Array<TableHeader<VehicleForm>> = [
      { text: 'imię', align: 'start', sortable: false, value: 'first_name' },
      { text: 'nazwisko', align: 'start', sortable: false, value: 'last_name' },
      { text: 'NIP', align: 'start', sortable: false, value: 'company_number' },
      { text: 'marka', align: 'start', sortable: false, value: 'car_manufacturer' },
      { text: 'model', align: 'start', sortable: false, value: 'car_model' },
      { text: 'rok produkcji', align: 'start', sortable: false, value: 'car_date' },
      { text: 'silnik', align: 'start', sortable: false, value: 'car_engine' },
      { text: 'przebieg', align: 'start', sortable: false, value: "mileage" },
      { text: 'problem', align: 'start', sortable: false, value: 'problem' },
      { text: 'nr telefonu', align: 'start', sortable: false, value: 'phone_number' },
      { text: 'nr rejestracyjny', align: 'start', sortable: false, value: 'car_plates_number' },
      { text: 'VIN', align: 'start', sortable: false, value: 'car_vin_number' },
      { text: 'zgoda na komunikację elektroniczną', align: 'start', sortable: false, value: 'electronic_communication_quarantine_agreed' },
      { text: 'zgoda RODO', align: 'start', sortable: false, value: 'data_protection_agreed' },
      { text: 'zgoda na jazdę próbną', align: 'start', sortable: false, value: 'vehicle_test_drive_agreed' },
      { text: 'zgoda na uzupełnienie plynow', align: 'start', sortable: false, value: 'fluids_refill_agreed' },
      { text: 'zgoda na uzupełnienie oleju', align: 'start', sortable: false, value: 'oil_refill_agreed' },
      { text: 'zwrot części', align: 'start', sortable: false, value: 'old_parts_return_required' },
      { text: 'uzupełnienie oświetlenia', align: 'start', sortable: false, value: 'lighting_refill_agreed' },
      { text: 'zgoda na ramkę', align: 'start', sortable: false, value: 'company_frame_montage_agreed' },
      { text: 'warunki naprawy', align: 'start', sortable: false, value: 'bosch_service_terms_agreed' },
      { text: 'informacje handlowe', align: 'start', sortable: false, value: 'electronic_trading_info_agreed' },
      { text: 'dezynfekcja 1', align: 'start', sortable: false, value: 'vehicle_disinfection_agreed' },
      { text: 'dezynfekcja 2', align: 'start', sortable: false, value: 'vehicle_disinfection_second_time_agreed' },
    ];
    return {
      vehicles,
      search,
      headers,
    };
  }
})
