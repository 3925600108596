














































import { defineComponent } from "@vue/composition-api";
import { useFleetForm } from "@/mixins/forms";

export default defineComponent({
  name: "FleetForm",
  props: {},
  setup(props, context) {
    return {
      ...useFleetForm(context),
    };
  },
});
