import { computed, ref, onMounted, onBeforeUnmount, reactive, SetupContext, watch } from "@vue/composition-api";
import { isNil } from "lodash";
import { API_ADDRESS, DATA_PROTECTION_AGREEMENT_PATH, FORM_TIMEOUT, DEBUG } from "@/config";
import moment from "moment";
import { sleep } from "@/helpers";
import { getVehicleLog, getFleetLog, getCachedData } from "@/requests.ts";
import type { VehicleForm, FleetForm, FleetFormDirty, VehicleFormDirty } from "@/models.ts";
const FIELD_MIN_CHARS = 2;

export enum FormPostState {
  Ready,
  AlreadyPosted,
  FormLoading,
}

function currentYear(): number {
  return moment().year();
}

function serializeTime(time: moment.Moment) {
  return time.toISOString();
}

function deserializeTime(time: string): moment.Moment {
  return moment(time);
}

/* Checks for a valid VIN
 *
 * Written by Brad Garland (bradg76 on github)
 *
 * With thanks to https://github.com/chilledham/Data-Validate-VIN
 *
 * Usage:
 *
 * valid_vin('12345678901234567');
 *
 * returns either true or false
 *
 * Open runner.html to run the jasmine tests
 */


function checkDigitCheck(vin: string): boolean {
  const cleaned_vin: string = vin.toUpperCase();

  const letter_map = {
    A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8,
    J: 1, K: 2, L: 3, M: 4, N: 5, P: 7, R: 9,
    S: 2, T: 3, U: 4, V: 5, W: 6, X: 7, Y: 8, Z: 9,
    1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 0: 0
  };
  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

  let products = 0;
  for (let i = 0; i < cleaned_vin.length; i++) {
    // alert('adding ' + letter_map[vin[i]] + ' * ' + weights[i] + ' to ' + products);
    products += letter_map[cleaned_vin[i] as keyof typeof letter_map] * weights[i];
  }
  let check_digit_should_be: string | number = products % 11;
  if (check_digit_should_be === 10) {
    check_digit_should_be = 'X';
  }

  return check_digit_should_be == cleaned_vin[8];
}

function validVin(vin: string): boolean {
  // Reject based on bad pattern match
  const no_ioq = '[a-hj-npr-z0-9]';  // Don't allow characters I,O or Q
  const matcher = new RegExp("^" + no_ioq + "{8}[0-9xX]" + no_ioq + "{8}$", 'i'); // Case insensitive
  if (vin.match(matcher) === null) return false;

  // Reject base on bad check digit
  return checkDigitCheck(vin);
}


// end of copy-pasted garbage. TODO: rewrite this

export function useFormTimeout(id: string) {
  function getLast(): moment.Moment | null {
    const entry = localStorage.getItem(id);
    return isNil(entry) ? null : deserializeTime(entry);
  }

  const formPostState = ref<FormPostState>(FormPostState.FormLoading);

  function setLast() {
    localStorage.setItem(id, serializeTime(moment()));
    formPostState.value = FormPostState.AlreadyPosted
  }

  function _canPost(): boolean {
    const last = getLast();
    return isNil(last) ? true : last.isBefore(moment().subtract(FORM_TIMEOUT));
  }


  const canPost = computed<boolean>(() => formPostState.value === FormPostState.Ready);

  const intervalHandler = ref(-1);

  onMounted(() => {
    intervalHandler.value = setInterval(() => {
      formPostState.value = _canPost() ? FormPostState.Ready : FormPostState.AlreadyPosted;
    }, 5000);
  });

  onBeforeUnmount(() => {
    clearInterval(intervalHandler.value);
  });

  const alreadyPosted = computed(() => formPostState.value === FormPostState.AlreadyPosted);

  return {
    formPostState,
    setLast,
    canPost,
    alreadyPosted,
  }
}

export function useValidation() {
  const textRules = ref([
    (v: string) => v.length >= FIELD_MIN_CHARS || `wymagane conajmniej ${FIELD_MIN_CHARS} znaki/-ów`,
  ]);

  const platesNumberRules = ref([
    ...textRules.value,
  ]);

  const descriptionRules = ref([
    (v: string) => v.length >= 5 || `wymagane conajmniej 5 znaków`,
  ]);

  const vinRules = ref([
    (v: string) => v.length > 15 && v.length < 20 || 'wprowadź poprawny numer nadwozia (na dowodzie rejestracyjnym)',
    // (v: string) => v.length >5 && validVin(v) || 'wprowadź poprawny numer nadwozia (na dowodzie rejestracyjnym)',
  ]);

  const miscRules = ref([
    (v: number | null) => !isNil(v) && (v as unknown as string !== '') || 'Pole wymagane',
  ]);
  
  const mileageRules = ref([
    (v: string) => !isNil(v) && !isNil(parseInt(v)) || 'proszę podać prawidłowy przebieg',
    
  ])

  const yearRules = ref([
    (v: string | null) => ((!isNil(v) && !isNil(parseInt(v)) && parseInt(v) <= currentYear() && parseInt(v) >= 1950) || 'Podaj prawidłowy rok, np "2004"'),
  ]);

  const phoneNumberRules = ref([
    (v: string) => !isNil(v) && !isNil(parseInt(v)) && v.length == 9 || 'format: 123456789',
  ]);

  const requiredCheckboxRules = ref([
    (v: boolean) => v || 'Pole wymagane',
  ]);

  const companyNumberRules = ref([
    (v: string) => v.length === 0 || (v.length === 10 && !isNaN(v as any)) || 'Podaj poprawny numer NIP (w celu uzyskania faktury VAT)'
  ]);

  return {
    textRules,
    descriptionRules,
    miscRules,
    yearRules,
    phoneNumberRules,
    vinRules,
    requiredCheckboxRules,
    platesNumberRules,
    companyNumberRules,
    mileageRules,
  }
}

export function useDataProtection() {
  const dataProtectionPath = ref(DATA_PROTECTION_AGREEMENT_PATH);

  return {
    dataProtectionPath,
  }
}

export function useVehicleForm(context: SetupContext) {
  const emptyForm: () => VehicleFormDirty = () => ({
    first_name: '',
    last_name: '',
    company_number: '',
    car_manufacturer: '',
    car_model: '',
    car_date: "",
    car_engine: '',
    problem: '',
    phone_number: '',
    electronic_communication_quarantine_agreed: false,
    data_protection_agreed: false,
    vehicle_test_drive_agreed: false,
    old_parts_return_required: false,
    car_plates_number: '',
    car_vin_number: '',
    mileage: null,
    fluids_refill_agreed: false,
    oil_refill_agreed: false,
    lighting_refill_agreed: false,
    company_frame_montage_agreed: false,
    bosch_service_terms_agreed: false,
    electronic_trading_info_agreed: false,
    vehicle_disinfection_agreed: false,
    vehicle_disinfection_second_time_agreed: false,
  });
  const form = DEBUG
    ? reactive<VehicleFormDirty>({
      car_vin_number: "WF0EXXGCDE7L07571",
      old_parts_return_required: false,

      vehicle_disinfection_agreed: false,
      vehicle_disinfection_second_time_agreed: false,

      vehicle_test_drive_agreed: false,
      first_name: 'Wojciech',
      last_name: 'Brożek',
      company_number: '8911632619',
      car_manufacturer: 'Volksvagen',
      car_model: 'Uno',
      car_date: "2004",
      car_engine: '1.9TDI',
      problem: 'Bardzo dobry samochód szybko jeździ',
      phone_number: '888888888',
      electronic_communication_quarantine_agreed: false,
      data_protection_agreed: false,
      mileage: '20000',
      car_plates_number: 'CAL29123',

      fluids_refill_agreed: false,
      oil_refill_agreed: false,
      lighting_refill_agreed: false,
      company_frame_montage_agreed: false,
      bosch_service_terms_agreed: false,
      electronic_trading_info_agreed: false,
    })
    : reactive<VehicleFormDirty>(emptyForm());
  const { ...rest } = useDataProtection();
  const { formPostState, setLast, canPost: canPost_, alreadyPosted } = useFormTimeout('vehicle-form');
  const canPost = computed(() => canPost_.value);
  const formHasErrors = ref<boolean>(false);
  const connectionError = ref<string | null>(null);
  const hasErrors = computed(() => formHasErrors.value || !isNil(connectionError.value));

  watch(
    () => form.car_plates_number,
    async (newStuff: string, oldStuff: string) => {
      if (newStuff.length < 3) { return; }
      const response = await getCachedData(newStuff);
      if (isNil(response)) { return; }
      form.car_vin_number = response.car_vin_number;
      form.car_date = response.car_date?.toString() ?? form.car_date;
      form.car_engine = response.car_engine ?? form.car_engine;
      form.car_manufacturer = response.car_manufacturer ?? form.car_manufacturer;
      form.car_model = response.car_model ?? form.car_model;
    }
  );

  async function submit() {
    const formHandler = (context as any).refs.form;
    const valid = formHandler.validate();
    await sleep(500);
    if (!valid || !canPost) {
      window.scroll({ top: 200, behavior: 'smooth' });
      alert('Błędnie wypełniony formularz, proszę poprawić pola oznaczone na czerwono.');
      return;
    }

    setLast();
    const formBody: VehicleForm = { ...form, phone_number: parseInt(form.phone_number), car_date: parseInt(form.car_date), mileage: parseInt(form.mileage!)};
    const response = await fetch(`${API_ADDRESS}/vehicle`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(formBody),
    });

    if (!response.ok) {
      response.text().then((data) => {
        connectionError.value = data;
      })
      return;
    }

    window.location.reload();
  }

  return {
    form,
    hasErrors,
    submit,
    ...useValidation(),
    connectionError,
    formHasErrors,
    canPost,
    formPostState,
    alreadyPosted,
    ...rest,
  }
}

export function useFleetForm(context: SetupContext) {
  const emptyForm: () => FleetFormDirty = () => ({
    // first_name: '',
    // last_name: '',
    // company_number: '',
    // car_manufacturer: '',
    // car_model: '',
    // car_date: '',
    // car_engine: '',
    // problem: '',
    // phone_number: '',
    // electronic_communication_quarantine_agreed: false,
    // data_protection_agreed: false,
    // vehicle_test_drive_agreed: false,
    // old_parts_return_required: false,
    // car_plates_number: '',
    // car_vin_number: '',

    // fluids_refill_agreed: false,
    // oil_refill_agreed: false,
    // lighting_refill_agreed: false,
    // company_frame_montage_agreed: false,
    // bosch_service_terms_agreed: false,
    // electronic_trading_info_agreed: false,
    // vehicle_disinfection_agreed: false,
    // vehicle_disinfection_second_time_agreed: false,
    first_name: '',
    last_name: '',
    car_card_number: '',
    mileage: null,
    car_plates_number: '',
    car_vin_number: '',
    data_protection_agreed: false,
    electronic_trading_info_agreed: false,
    phone_number: '',
    problem: '',
  });
  const form = DEBUG
    ? reactive<FleetFormDirty>({
      first_name: 'Wojciech',
      last_name: 'Brożek',
      car_card_number: 'WF0EXXGCDE7L07571',
      car_plates_number: 'CAL29123',
      car_vin_number: 'WF0EXXGCDE7L07571',
      data_protection_agreed: false,
      electronic_trading_info_agreed: false,
      phone_number: '888888888',
      problem: 'Coś nie działa',
      mileage: '20000',
    })
    : reactive<FleetFormDirty>(emptyForm());
  const { ...rest } = useDataProtection();
  const { formPostState, setLast, canPost: canPost_, alreadyPosted } = useFormTimeout('fleet-form');
  const canPost = computed(() => canPost_.value);
  const formHasErrors = ref<boolean>(false);
  const connectionError = ref<string | null>(null);
  const hasErrors = computed(() => formHasErrors.value || !isNil(connectionError.value));
  watch(
    () => form.car_plates_number,
    async (newStuff: string, oldStuff: string) => {
      if (newStuff.length < 3) { return; }
      const response = await getCachedData(newStuff);
      if (isNil(response)) { return; }
      form.car_vin_number = response.car_vin_number;
      form.car_card_number = response.car_card_number ?? form.car_card_number;
    }
  );
  async function submit() {
    const formHandler = (context as any).refs.form;
    const valid = formHandler.validate();
    await sleep(500);
    if (!valid || !canPost) {
      window.scroll({ top: 200, behavior: 'smooth' });
      alert('Błędnie wypełniony formularz, proszę poprawić pola oznaczone na czerwono.');
      return;
    }

    setLast();
    const formBody: FleetForm = { ...form, phone_number: parseInt(form.phone_number), mileage: parseInt(form.mileage!)};
    const response = await fetch(`${API_ADDRESS}/fleet`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(formBody),
    });

    if (!response.ok) {
      response.text().then((data) => {
        connectionError.value = data;
      })
      return;
    }

    window.location.reload();
  }

  return {
    form,
    hasErrors,
    submit,
    ...useValidation(),
    connectionError,
    formHasErrors,
    canPost,
    formPostState,
    alreadyPosted,
    ...rest,
  }
}

export function useVehicleLog(props: { secret: string }) {
  const vehicles = ref<VehicleForm[]>([]);

  const getVehicles = async () => {
    vehicles.value = await getVehicleLog(props.secret);
  }

  onMounted(getVehicles);

  return {
    vehicles,
  }
}


export function useFleetVehicleLog(props: { secret: string }) {
  const vehicles = ref<FleetForm[]>([]);

  const getVehicles = async () => {
    vehicles.value = await getFleetLog(props.secret);
  }

  onMounted(getVehicles);

  return {
    vehicles,
  }
}

