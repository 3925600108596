import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VehicleLog from '@/views/VehicleLog.vue';
import FleetForm from '@/views/FleetForm.vue';
import FleetLog from '@/views/FleetLog.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fleet',
    name: 'fleet-form',
    component: FleetForm,
  },
  {
    path: '/past/:secret',
    component: VehicleLog,
    props: true,
  },
  {
    path: '/past-fleet/:secret',
    component: FleetLog,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
