import {API_ADDRESS} from '@/config.ts';
import {VehicleForm, FleetForm, CachedData} from '@/models.ts';

export async function getVehicleLog(secret: string): Promise<VehicleForm[]> {
    const address = `${API_ADDRESS}/past/${secret}`;
    const response = await fetch(address);
    return await response.json();
}
export async function getFleetLog(secret: string): Promise<FleetForm[]> {
    const address = `${API_ADDRESS}/past-fleet/${secret}`;
    const response = await fetch(address);
    return await response.json();
}
export async function getCachedData(car_plates_number: string): Promise<CachedData | null> {
    const address = `${API_ADDRESS}/cached-data/${car_plates_number}`;
    const response = await fetch(address);
    if (!response.ok) { return null }
    return await response.json();
}
