















import { defineComponent, ref } from '@vue/composition-api';
import { useFleetVehicleLog } from '@/mixins/forms';
import { TableHeader, FleetForm } from '@/models';
export default defineComponent({
  name: 'FleetLog',
  props: {
    secret: {
      type: String,
      required: true,
    }
  },
  setup(props, context) {
    const { vehicles } = useFleetVehicleLog(props);
    const search = ref("");
    const headers: Array<TableHeader<FleetForm>> = [
      { text: 'imię kierowcy', align: 'start', sortable: false, value: 'first_name' },
      { text: 'nazwisko kierowcy', align: 'start', sortable: true, value: 'first_name' },
      { text: 'VIN', align: 'start', sortable: false, value: 'car_vin_number' },
      { text: 'przebieg', align: 'start', sortable: false, value: "mileage" },
      { text: 'nr rejestracyjny', align: 'start', sortable: true, value: 'car_plates_number' },
      { text: 'nr telefonu kierowcy', align: 'start', sortable: true, value: 'phone_number' },
      { text: 'problem', align: 'start', sortable: false, value: 'problem' },
      { text: 'numer karty pojazdu', align: 'start', sortable: false, value: 'car_card_number' },
      { text: 'informacje handlowe', align: 'start', sortable: false, value: 'electronic_trading_info_agreed' },
      { text: 'zgoda RODO', align: 'start', sortable: false, value: 'data_protection_agreed' },
    ];
    return {
      vehicles,
      search,
      headers,
    };
  },
});
