




























































import Vue from "vue";
import HelloWorld from "./components/HelloWorld.vue";

export default Vue.extend({
  name: "App",

  components: {},
  methods: {
      redirect(url: string) {
          window.location = url as any;
      },
  },
  data: () => ({
    //
  }),
});
