





























































































import { defineComponent } from "@vue/composition-api";
import { useVehicleForm } from "@/mixins/forms";

export default defineComponent({
  name: "Home",
  setup(_props, context) {
    return {
      ...useVehicleForm(context),
    };
  },
});
